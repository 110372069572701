// components
import '../style/sidebar.scss'
// images
import logo from "../img/swart.jpg"
// icons
import { FiDownload } from "react-icons/fi";


const Sidebar = (props: {setTab: (tabName: string) => void, tab: string}) => {
  return (
    <>
      <img className="logo" src={logo} alt="SoftwareArt" onClick={() => window.location.reload()}/>
      <div className={props.tab === "home" ? "fadeOut":"fadeIn"}>
        {props.tab !== "null" && 
          <span className="cvDownlaod">
            <a href={"https://cv.swartsolutions.com/ArturStankiewiczCV.pdf"} target='_self' rel="noreferrer" download>
              <FiDownload size={20}/>&nbsp;<span>Download&nbsp;</span>CV
            </a>
          </span>}
      </div>
      <ul className="menu">
        <li className={props.tab === "home" || props.tab === "null" ? "activeMenue":""}><span 
        onClick={() => 
          {if(props.tab !== "null") {
            props.setTab("home")
          }}
        }>01 Home</span></li>
        <li className={props.tab === "about" ? "activeMenue":""} ><span onClick={() => props.setTab("about")}>02 About</span></li>
        <li className={props.tab === "skills" ? "activeMenue":""} ><span onClick={() => props.setTab("skills")}>03 Skills</span></li>
        <li className={props.tab === "contact" ? "activeMenue":""} ><span onClick={() => props.setTab("contact")}>04 Contact</span></li>
      </ul>
    </>
  );
};

export default Sidebar;
