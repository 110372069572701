import { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import '../style/emailForm.scss';

export const EmailForm = () => {
    const formRef = useRef<any>();
    const inputRef = useRef<any>();
    const textareaRef = useRef<any>();
    
    const [toast, setToast] = useState<boolean>(false)
    const [spinner, setSpinner] = useState<boolean>(false)

    useEffect(() => {
        setSpinner(false);
        if(toast === true) {
            inputRef.current.value = ''
            textareaRef.current.value = ''
            const timer = setTimeout(() => setToast(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [toast])

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSpinner(true);

        emailjs.sendForm('service_30ku1ho', 'template_2urdpco', formRef.current, 'Fu7Mtt13B9zEnvNMZ')
            .then((result) => {
                setToast(true)
            }, (error) => {
                console.log(error.text);
                console.log("error sending message, try again!")
            });
        };
    return (
        <form ref={formRef} onSubmit={sendEmail}>
            {toast && <div id="snackbar" className={"show"}>Thank you for your message!</div>}
            <input ref={inputRef} name='user_email' type="email" placeholder='Email' required />
            <textarea ref={textareaRef} name='user_message' placeholder='Write message...' required ></textarea>
            <button type="submit" className="send-button">Send Message {spinner && <span className="loader"></span>}</button>
        </form>
    )};