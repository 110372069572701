// style
import '../style/content.scss'
// images
import myPhoto from "../img/ja.jpg"
// icons
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
// components
import { EmailForm } from "./Email"

const Contact = (props: {active: boolean}) => {
  return (
    <>
      {props.active === true && 
        <div className="content-section">
          <h1>
            <p className="typing">Contact</p>
          </h1>
          <div className="fade-in">
            <div className="email-photo-section">
              <div className="contact-section">
                <img className="my-photo box" src={myPhoto} alt=""/>
                <div>
                  <FaPhone className="contact-icon"/><p className="contact-text">+48 531 501 636</p>
                </div>
                <div>
                  <MdEmail className="contact-icon"/><p className="contact-text">axstankiewicz@gmail.com</p>
                </div>
              </div>
              <EmailForm />
            </div>
          </div>
        </div>
      }
    </>
  )
};

export default Contact;
