// style
import '../style/content.scss'
// components

const About = (props: {active: boolean}) => {
  return (
    <>
      {props.active === true && 
        <div className="content-section">
          <h1>
            <p className="typing">About me</p>
          </h1>
          <div className="fade-in">
            <div>
              Passionate programming enthusiast with a love for tackling challenges head-on. 
              Adept at navigating complex coding scenarios, always eager to learn and explore new technologies. 
            </div>
          </div>
        </div>
      }
    </>
  )
};

export default About;
