// style
import { useState, useEffect } from 'react';
import '../style/content.scss'
// icons
import { FiDownload } from "react-icons/fi";
// components


const Home = (props: {active: boolean}) => {
  const [addBorder, setAddBorder] = useState(false);
  const [cvDownloaded, setCvdownloaded] = useState(false)

  useEffect(() => {
    if(cvDownloaded === true) {
      const timer = setTimeout(() => setAddBorder(true), 600);
      return () => clearTimeout(timer);
    }
  }, [cvDownloaded]);

  useEffect(() => {
    if(props.active === false) {
      setAddBorder(false);
      setCvdownloaded(false);
    }
  }, [props.active]);

  return (
    <>
      {props.active === true && 
      <>
        <div className="content-section">
          <h1>
            <p className="typing">Hi, I'm Artur</p>
          </h1>
          <div className="fade-in">
            <div>
              <div>
                If you're seeking for my detailed professional background, you can download my CV by clicking the button below.
              </div>
              <a href={"https://cv.swartsolutions.com/ArturStankiewiczCV.pdf"} target='_self' rel="noreferrer" download>
                <button className={"cvButton draw " + (addBorder && "cvButtonClicked")}
                onClick={
                  () => {
                    setCvdownloaded(true);
                  }
                }
                >
                  <FiDownload size={20}/>&nbsp;<span>Download&nbsp;</span>CV
                </button>
              </a>
            </div>
          </div>
        </div>
      </>
      }
    </>
  )
};

export default Home;
