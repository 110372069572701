// react
import { useState } from 'react';
// components
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';
// style
import './App.scss';

function App() {
  const [tab, setTab] = useState<string>("null");

  return (
    <div className="App">
        <Sidebar setTab={setTab} tab={tab}/>
        <div className="Content">
            <Home active={tab === "home" || tab === "null"}/>
            <About active={tab === "about"}/>
            <Skills active={tab === "skills"}/>
            <Contact active={tab === "contact"}/>
        </div>
    </div>
  );
}

export default App;
