// style
import '../style/content.scss'
// icons
import { FaAws } from "react-icons/fa";
import { SiTerraform } from "react-icons/si";
import { FaDocker } from "react-icons/fa";
import { SiKubernetes } from "react-icons/si";
import { SiJenkins } from "react-icons/si";
import { FaPython } from "react-icons/fa";
import { DiDjango } from "react-icons/di";
import { BsFiletypeSql } from "react-icons/bs";
import { SiMongodb } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { BsFiletypeHtml } from "react-icons/bs";
import { BsFiletypeCss } from "react-icons/bs";
import { FaReact } from "react-icons/fa";
// import { FaBootstrap } from "react-icons/fa";
// import { FaSass } from "react-icons/fa";
// import { FaGitSquare } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import { FaSquareGitlab } from "react-icons/fa6";
import { FaBitbucket } from "react-icons/fa";
import { FaJira } from "react-icons/fa";
import { SiAzuredevops } from "react-icons/si";
import { SiGnubash } from "react-icons/si";
// react tooltips
import { Tooltip } from 'react-tooltip'

const Skills = (props: {active: boolean}) => {
  return (
    <>
      {props.active === true && 
        <div className="content-section skills-content">
          <h1>
            <p className="typing">Skills</p>
          </h1>
          <div className="fade-in">
            <h5>DevOps:</h5>
            <div className="skills-section">
              <FaAws id="AWS" className="skills-icon"/>
              <Tooltip anchorSelect="#AWS" place="top">
                AWS
              </Tooltip>
              <SiTerraform id="Terraform" className="skills-icon"/>
              <Tooltip anchorSelect="#Terraform" place="top">
                Terraform
              </Tooltip>
              <FaDocker id="Docker" className="skills-icon"/>
              <Tooltip anchorSelect="#Docker" place="top">
                Docker
              </Tooltip>
              <SiKubernetes id="Kubernetes" className="skills-icon"/>
              <Tooltip anchorSelect="#Kubernetes" place="top">
                Kubernetes
              </Tooltip>
              <SiJenkins id="Jenkins" className="skills-icon"/>
              <Tooltip anchorSelect="#Jenkins" place="top">
                Jenkins
              </Tooltip>
            </div>
            <h5>Backend:</h5>
            <div className="skills-section">
              <FaPython id="Python" className="skills-icon"/>
              <Tooltip anchorSelect="#Python" place="top">
                Python
              </Tooltip>
              <DiDjango id="Django" className="skills-icon"/>
              <Tooltip anchorSelect="#Django" place="top">
                Django
              </Tooltip>
              <BsFiletypeSql id="SQL" className="skills-icon"/>
              <Tooltip anchorSelect="#SQL" place="top">
                SQL
              </Tooltip>
              <SiMongodb id="MongoDB" className="skills-icon"/>
              <Tooltip anchorSelect="#MongoDB" place="top">
                MongoDB
              </Tooltip>
              <SiGnubash id="Bash" className="skills-icon"/>
              <Tooltip anchorSelect="#Bash" place="top">
                Bash
              </Tooltip>
            </div>
            <h5>Frontend:</h5>
            <div className="skills-section">
              <SiJavascript id="JavaScript" className="skills-icon"/>
              <Tooltip anchorSelect="#JavaScript" place="top">
                JavaScript
              </Tooltip>
              <SiTypescript id="TypeScript" className="skills-icon"/>
              <Tooltip anchorSelect="#TypeScript" place="top">
                TypeScript
              </Tooltip>
              <BsFiletypeHtml id="HTML" className="skills-icon"/>
              <Tooltip anchorSelect="#HTML" place="top">
                HTML
              </Tooltip>
              <BsFiletypeCss id="CSS" className="skills-icon"/>
              <Tooltip anchorSelect="#CSS" place="top">
                CSS
              </Tooltip>
              <FaReact id="React" className="skills-icon"/>
              <Tooltip anchorSelect="#React" place="top">
                React
              </Tooltip>
            </div>
            <h5>Other:</h5>
            <div className="skills-section">
              <FaGithub id="GitHub" className="skills-icon"/>
              <Tooltip anchorSelect="#GitHub" place="top">
                GitHub
              </Tooltip>
              <FaSquareGitlab id="GitLab" className="skills-icon"/>
              <Tooltip anchorSelect="#GitLab" place="top">
                GitLab
              </Tooltip>
              <FaBitbucket id="Bitbucket" className="skills-icon"/>
              <Tooltip anchorSelect="#Bitbucket" place="top">
                Bitbucket
              </Tooltip>
              <FaJira id="Jira" className="skills-icon"/>
              <Tooltip anchorSelect="#Jira" place="top">
                Jira
              </Tooltip>
              <SiAzuredevops id="AzureDevOps" className="skills-icon"/>
              <Tooltip anchorSelect="#AzureDevOps" place="top">
                Azure DevOps
              </Tooltip>
            </div>
          </div>
        </div>
      }
    </>
  )
};

export default Skills;
